import Model from "./Model";
import User from "@/models/User";

export default class District extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  country = null;
  state = null;
  city = null;
  street = null;
  zipcode = null;
  image = null;
  schools_count = null;
  managers_count = null;
  created_at = null;
  updated_at = null;
  school_ids = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }
  // district
  async store() {
    return this._store(`districts/${this.id}`);
  }

  async restore() {
    return Model._restore(District, `districts/${this.id}`);
  }

  async load() {
    return Model._load(District, `districts/${this.id}.json?rostering=true&schools=true&licenses=true`);
  }

  // importing/rostering
  async updateRosteringInfo(params) {
    let path = this.id && this.id.length > 0 ? `districts/${this.id}.json` : "districts.json";
    await this._patch(District, path, params);
    const response = await this.load(this.id);
    return response;
  }

  async updateDistrictInfo(params) {
    let path = this.id && this.id.length > 0 ? `districts/${this.id}.json` : "districts.json";
    await this._patch(District, path, params);
    const response = await this.load(this.id);
    return response;
  }

  async manualUpload(params) {
    const path = `districts/${this.id}/imports.json`;
    const response = await this._post(District, path, params);
    return response;
  }

  async runSftpImport() {
    return this._post(District, `/districts/${this.id}/start_import.json`);
  }

  async runCleverClassLinkImport() {
    return this._post(District, `/districts/${this.id}/start_import.json`);
  }

  async recycleSftpPassword() {
    return this._patch(District, `/districts/${this.id}/cycle_password.json?rostering=true`);
  }

  // import logs
  async loadImportLogs(params) {
    const path = `districts/${this.id}/imports.json`;
    const response = await this._getWithPagination(path, params);
    return response;
  }

  async loadImportLogDetails(logId) {
    const path = `districts/${this.id}/imports/${logId}.json`;
    const response = await this._get(path);
    return response;
  }

  async isImportRunning() {
    const path = `districts/${this.id}/import_running.json`;
    const response = await this._get(path);
    return response;
  }

  // users
  async loadUsers(params) {
    const path = "/users.json";
    const response = await this._getWithPagination(path, params);
    return response;
  }

  async updateUser(params) {
    const path = `/users/${params.user.id}.json`;
    const response = await this._put(User, path, params);
    return response;
  }

  async loadUserData(params) {
    const path = `/users/${params.user}.json`;
    const response = await this._get(path);
    return response;
  }

  async inviteUser(params) {
    // :type, :name, :first_name, :last_name, :alternate_identifier, :email, :district_id, :school_id, :classroom_id
    const path = "/invitations/invite.json";
    const response = await this._post(User, path, params);
    return response;
  }

  async resendInvite(params) {
    // :email
    const path = "/invitations/resend.json";
    const response = await this._post(User, path, params);
    return response;
  }

  async archiveUser(params) {
    const path = `/users/${params.user}.json`;
    const response = await this._delete(path);
    return response;
  }

  async restoreUser(params) {
    const path = "/users/restore.json";
    const response = await this._post(User, path, params);
    return response;
  }

  async bulkUpdateUsers(params) {
    const path = "/users/bulk_update.json";
    const response = await this._patch(User, path, params);
    return response;
  }

  async bulkInvite(params) {
    const path = "invitations/bulk.json";
    const response = await this._post(User, path, params);
    return response;
  }

  // stats
  async loadMostViewed(params) {
    const path = "/stats/most_viewed.json";
    const response = await this._getWithPagination(path, params);
    return response?.data.most_viewed;
  }
}
