import {
  createStore
} from "vuex";
import VuexPersistence from "vuex-persist";

import { storageKey } from "@/configKeys";

import terms from "./terms";
import users from "./users";
import notes from "./notes";
import responses from "./responses";
import supports from "./supports";
import schools from "./schools";
import classrooms from "./classrooms";
import assistants from "./assistants";
import teachers from "./teachers";
import students from "./students";
import announcements from "./announcements";
import questions from "./questions";
import answers from "./answers";
import passwords from "./passwords";
import invitations from "./invitations";
import comments from "./comments";
import districts from "./districts";
import dashboard from "./dashboard";
import ux from "./ux";
import lti from "./lti";
import dataExports from "./data_exports";
import tempUx from "./tempUx";

const plugins = [
  new VuexPersistence({
    key: storageKey,
    storage: window.localStorage,
    reducer: state => ({
      dashboard: {
        classFilter: state.dashboard.classFilter,
        schoolFilter: state.dashboard.schoolFilter,
        timePeriod: state.dashboard.timePeriod
      },
      ux: state.ux
    })
  }).plugin
];

const store = createStore({
  plugins,
  modules: {
    terms,
    users,
    notes,
    responses,
    supports,
    schools,
    classrooms,
    assistants,
    teachers,
    students,
    announcements,
    questions,
    answers,
    passwords,
    invitations,
    comments,
    districts,
    dashboard,
    ux,
    lti,
    dataExports,
    tempUx
  }
});

export default store;
